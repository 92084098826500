import { Food } from 'components/Food';
import { FoodList } from 'components/FoodList';
import { PlaceHero } from 'components/PlaceHero';
import SEO from 'components/SEO';
import Layout from 'components/layout';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  VORFOR_COFFEE,
  VORFOR_DISH,
  VORFOR_JUICE,
  VORFOR_MILKSHAKES,
  VORFOR_VAFLER,
} from '../data/food';

const VorforVaffelPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "venue-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "venue-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      food: allFile(filter: { name: { regex: "/(vorfor-)/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout>
      <SEO title="Vorfor Vaffel" />
      <div className="max-w-screen-xl px-6 sm:mx-auto py-4 md:py-8">
        <PlaceHero
          title="Vorfor Vaffel"
          phone="26563581"
          address={[
            {
              street: 'Lerchesgade 4',
              city: '5000 Odense C',
              map: 'https://maps.app.goo.gl/8n2tbykBxAwJk7r89',
            },
            {
              street: 'Nicoline Kochs Plads Pl 4',
              city: '8000 Aarhus C',
              map: 'https://maps.app.goo.gl/CS48qxy1957guqxX6',
            },
          ]}
          social={{
            facebook: 'https://www.facebook.com/VorforVaffel',
            instagram: 'https://www.instagram.com/vorforvaffel/?hl=en',
          }}
          sources={sources}
          openingHours={[
            {
              title: 'Søndag - Torsdag',
              time: '11:00-21:00',
            },
            {
              title: 'Fredag - Lørdag',
              time: '11:00-22:00',
            },
          ]}
        />

        {/* <InfoBox phone="26563581" /> */}

        <FoodList title="Vafler">
          {VORFOR_VAFLER.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Retter">
          {VORFOR_DISH.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Milkshakes">
          {VORFOR_MILKSHAKES.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Kaffe">
          {VORFOR_COFFEE.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Juice">
          {VORFOR_JUICE.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
      </div>
    </Layout>
  );
};

export default VorforVaffelPage;
